import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import StaticSideMenu from "../components/StaticSideMenu";
import { Link } from "react-router-dom";
import {
  CDN_URL
} from "../config/ApiConfig"
import moment from "moment"

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackURL: 'https://feedback.slowly.app/changelog'
    };
  }

  componentDidMount(){
    const {
      email, name, id, gender, avatar, created_at
      // location_code
     } = this.props.me

    if(!!email){
      const avatarURL = avatar.length <=2 ? CDN_URL + "/assets/images/avatar/"+gender + "/" + avatar + ".png"
                                : CDN_URL + "/assets/images/avatar/other/" + avatar + ".png"
      window['identifyCanny']({
        // Replace these values with the current user's data
        email,
        name,
        id,
        // These fields are optional, but recommended:
        avatarURL,
        created: moment(created_at).format()
      })
      // this.setState({
      //   feedbackURL: 'https://feedback.slowly.app/changelog'
      // })
    }
  }

  render() {
    const { locale } = this.props;
    const webLocale = locale==='en' ? '' : locale==='zh_Hant' ? '/zh' : locale==='zh_Hans' ? '/cn' : locale==='pt_BR' ? '/pb' : '/'+ locale;

    return (
      <div className="split-md mx-auto">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-3">
              <StaticSideMenu idv_available={this.props.me.idv_available} />
            </div>
            <div className="col-9 p-3">

              {/* TAGS */}
              <div className="card shadow-sm  my-2">
                <div className="card-header">{I18n.t("ABOUT_SLOWLY")}</div>
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-12">
                      <a href="https://campaign.slowly.app/stampunlocked/" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <span className="noto-emoji icon-sw">📸</span>
                        #StampUnlocked Photo Challenge 
                      </a>
                    </div> */}

                    <div className="col-6">
                      <a href="https://help.slowly.app" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <span className="noto-emoji icon-sw">📖</span> {I18n.t('FAQ')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={"https://help.slowly.app/hc/en-us/requests/new"} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <span className="noto-emoji icon-sw">✉️</span> {I18n.t('ASK_FEEDBACK')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={'https://forms.slowly.app/feature-request/'} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <span className="noto-emoji icon-sw">💡</span> {I18n.t('Feature Request')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={this.state.feedbackURL} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                        <span className="noto-emoji icon-sw">📣</span> {I18n.t('Stamp Request')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={"https://slowly.app"+webLocale+"/community-guidelines/"} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                      <span className="noto-emoji icon-sw">🤝</span> {I18n.t('COMMUNITY_GUIDELINES')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={"https://slowly.app"+webLocale+"/safety-tips/"} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                      <span className="noto-emoji icon-sw">🔒</span> {I18n.t('Safety Tips')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={"https://slowly.app/terms/"} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                      <span className="noto-emoji icon-sw">📝</span> {I18n.t('TERMS')}
                      </a>
                    </div>

                    <div className="col-6">
                      <a href={"https://slowly.app/privacy/"} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                      <span className="noto-emoji icon-sw">🔍</span> {I18n.t('PRIVACY')}
                      </a>
                    </div>

                  </div>
                </div>
              </div>

              <div className="card shadow-sm mt-3 ">
                <div className="card-header">{I18n.t("SUPPORT_US")}</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <Link to="/upgrade" className="btn btn-default">
                        <span className="noto-emoji icon-sw">🎁</span>  {I18n.t('UPGRADE_TO_SLOWLY_PLUS')}
                      </Link>
                    </div>
                    <div className="col-6">
                      <a href="https://www.buymeacoffee.com/slowly/" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                          
                        <span className="noto-emoji icon-sw">☕️</span>  {I18n.t('BUY_US_A_COFFEE')}
                      </a>
                    </div>

                  </div>
                </div>
              </div>

              <div className="card shadow-sm mt-3 ">
                <div className="card-header">{I18n.t("FOLLOW_US")}</div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <a href={"https://slowly.app"+webLocale+"/blog/"} target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                          <i className="icon-news text-light icon-sw"/> {I18n.t('Slowly Blog')}
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="https://www.instagram.com/slowlyapp" target="_blank" rel="noopener noreferrer" className="btn btn-default">
                          <i className="icon-instagram text-instagram icon-sw"/> Instagram
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="https://www.facebook.com/slowlyapp" target="_blank" rel="noopener noreferrer" className="btn btn-default">
                          <i className="icon-facebook text-facebook icon-sw"/> Facebook
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="https://x.com/slowlyapp" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                          <i className="icon-x text-black icon-sw"/> X / Twitter
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="https://medium.com/slowlyapp" target="_blank" rel="noopener noreferrer" className="btn btn-default">
                          <i className="icon-medium text-instagram icon-sw"/> Medium
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="https://www.pinterest.com/slowlyapp/" target="_blank"  rel="noopener noreferrer" className="btn btn-default">
                          <i className="icon-pinterest icon-sw" style={{color: '#E60023' }} /> Pinterest
                      </a>
                    </div>
                  </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale
  };
};

export default connect(
  mapStateToProps,
  {
  }
)(About);
